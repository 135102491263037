import React, { useState } from 'react';
import BannerData from './BannerData';
import './Banner.css';
import ButtonSlider from './ButtonSlider';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';



import 'swiper/css/autoplay';



const BannerSlider = () => {
 const [slideIndex, setSlideIndex]=useState(1)
 
 const nextSlide = () => {
  if(slideIndex !== BannerData.length){
    setSlideIndex(slideIndex + 1)
} 
else if (slideIndex === BannerData.length){
    setSlideIndex(1)
}

 }
 const prevSlide= () =>{
  if(slideIndex !== 1){
    setSlideIndex(slideIndex - 1)
}
else if (slideIndex === 1){
    setSlideIndex(BannerData.length)
}
 }
 const moveDot = index => {
  setSlideIndex(index)
}


  return (
  <div className='images'>
      <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      slidesPerView={1}
      navigation
      autoplay={{ delay: 5000 }}
      pagination={{ clickable: true }}
    
    >
  
    
    <div className='container-slider'>
        {BannerData.map((obj, index) => {
          return (
            <div>
            <SwiperSlide key={obj.id}>
            className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
            
              
                <img 
                src={process.env.PUBLIC_URL + `/BannerImg/banner${index + 1}.jpg` } alt='banner'/>
                {/* <div className='container_content'>
                  <h1 className='h1content_text'>{obj.title}</h1>
                    <h2 className='h2content_text'>{obj.subTitle}</h2>
                  </div> */}
                  </SwiperSlide>
               
               <div classname='news-container'></div>
               <div classname='jobs-container'></div>
            </div>
        )
                

         })
        }
        <ButtonSlider moveSlide={nextSlide} direction={"next"} />
            <ButtonSlider moveSlide={prevSlide} direction={"prev"}/>

            <div className="container-dots">
                {Array.from({length: 10}).map((item, index) => (
                    <div key={index}
                    onClick={() => moveDot(index + 1)}
                    className={slideIndex === index + 1 ? "dot active" : "dot"}
                    ></div>
                ))}
            </div>
    </div>
    </Swiper>
  
    </div>
  );
}

export default BannerSlider;